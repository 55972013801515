import React, { useContext }  from "react";
import Header from "../../../components/header";
import Form from "../../../components/form";
import { ProductContext } from "../../../utils/productContext";
import { useEffect } from "react";
import { buySellFinancelistRequest } from "../../../store/actions/buySellFinanceList";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";



export default function ExpenseLiquidation({handleChange,liquidation,chassisDisable}){

  const [financerDropdown, setFinancerDropdown] = useState([]);
  const dispatch =  useDispatch();
  const sellFinanaceObj = useSelector(state => state.buySellFinanceListReducer);
  const { loading, resFinList } = sellFinanaceObj;

  useEffect(() => {
    dispatch(buySellFinancelistRequest({key:"selling_category_financers"}))
  },[])

  useEffect(()=>{
    if(resFinList){
      let financerDropVar = [{value:"",label:"Select..."}];
      resFinList?.data?.selling_category_financers?.map((item,index)=>{
        if(item){
          financerDropVar = [...financerDropVar, {value:item,label:item}]
        }
      })
      setFinancerDropdown(financerDropVar)
    }
  },[resFinList])


    const {
        errors
      } = useContext(ProductContext);

    const soldto_dropdown = [
        {value:"", label:"Select..."},
        {value:"Farmer", label:"Farmer"},
        {value:"Broker", label:"Broker"}
        ]  
     
    const yes_no_drodpwn = [
            {value:"",label:"Select..."},
            {value:"Yes",label:"Yes"},
            {value:"No",label:"No"}
    ]  
    
    const warranty_name_dropdown = [
            {value:"",label:"Select..."},
            {value:"CW",label:"CWI"},
            {value:"Kavach",label:"Kavach"}
    ]  
    
    // const financerDropdown = [
    //   {value:"",label:"Select..."},
    //   {value:"M&MFSL", label:"M&MFSL"},
    //   {value:"L&T", label:"L&T"},
    //   {value:"Kotak", label:"Kotak"},
    //   {value:"Chola", label:"Chola"},
    //   {value:"TVS", label:"TVS"},
    //   {value:"SK Finance", label:"SK Finance"},
    //   {value:"IndusInd", label:"IndusInd"},
    //   {value:"Shriram", label:"Shriram"},
    //   {value:"Axis", label:"Axis"},
    //   {value:"Bank of Baroda", label:"Bank of Baroda"},
    //   {value:"AU Bank", label:"AU Bank"},
    //   {value:"SBI", label:"SBI"},
    //   {value:"HDFC", label:"HDFC"},
    //   {value:"Other", label:"Other"}
    // ]

    const styles={width:"100%",maxWidth: "100%"};


    return (
        <>
        <Header>
        <div style={{display:'flex',alignItems:'center',gap:'5px',color:'#FFFF',marginLeft:'8px'}}>
            <p>Selling</p>
            </div>
        </Header>
        <>
                    <Form.GroupFields>
                            <Form.Label htmlfor="sale_date">
                            Sale Date
                            <span
                                  style={{ color: "red", marginLeft: "2px" }}
                                >
                                  *
                            </span>                            
                            </Form.Label>
                            <Form.Input
                              name="sale_date"
                              id="sale_date"
                              type="date"
                              onChange={(e) => handleChange(e)}
                              value={liquidation["sale_date"]}
                              style={styles}
                            />
                            {(errors?.hasOwnProperty("sale_date") && errors["sale_date"]) && <h5 style={{color: 'red',marginTop:'2px'}}>{errors["sale_date"]}</h5>}                            
                    </Form.GroupFields>
                    <Form.GroupFields>
                            <Form.Label htmlfor="selling_price">
                            Selling Price
                            <span
                                  style={{ color: "red", marginLeft: "2px" }}
                                >
                                  *
                            </span>                            
                            </Form.Label>
                            <Form.Input
                              name="selling_price"
                              id="selling_price"
                              type="number"
                              onChange={(e) => handleChange(e)}
                              value={liquidation["selling_price"]}
                              style={styles}
                            />
                            {(errors?.hasOwnProperty("selling_price") && errors["selling_price"]) && <h5 style={{color: 'red',marginTop:'2px'}}>{errors["selling_price"]}</h5>}                            
                    </Form.GroupFields>
                    <Form.GroupFields>
                        <Form.Label htmlfor="sold_to">
                        Sold to
                        <span
                              style={{ color: "red", marginLeft: "2px" }}
                          >
                              *
                        </span>
                        </Form.Label>
                        <Form.Dropdown
                            name="sold_to"
                            id="sold_to"
                            options={soldto_dropdown}
                            onChange={(e) => handleChange(e)}
                            value={liquidation["sold_to"]}
                            style={styles}
                        />
                            {(errors?.hasOwnProperty("sold_to") && errors["sold_to"]) && <h5 style={{color: 'red',marginTop:'2px'}}>{errors["sold_to"]}</h5>}                            
                    </Form.GroupFields>
                    <Form.GroupFields>
                            <Form.Label htmlfor="name_of_buyer">
                            Name of Buyer
                            <span
                                style={{ color: "red", marginLeft: "2px" }}
                            >
                                *
                            </span>
                            </Form.Label>
                            <Form.Input
                              name="name_of_buyer"
                              id="name_of_buyer"
                              type="text"
                              onChange={(e) => handleChange(e)}
                              value={liquidation["name_of_buyer"]}
                              style={styles}
                            />
                            {(errors?.hasOwnProperty("name_of_buyer") && errors["name_of_buyer"]) && <h5 style={{color: 'red',marginTop:'2px'}}>{errors["name_of_buyer"]}</h5>}                            
                    </Form.GroupFields>
                    <Form.GroupFields>
                            <Form.Label htmlfor="contact_of_buyer">
                            Contact of Buyer
                            </Form.Label>
                            <Form.Input
                              name="contact_of_buyer"
                              id="contact_of_buyer"
                              type="number"
                              onChange={(e) => handleChange(e)}
                              value={liquidation["contact_of_buyer"]}
                              style={styles}
                            />
                            {(errors?.hasOwnProperty("contact_of_buyer") && errors["contact_of_buyer"]) && <h5 style={{color: 'red',marginTop:'2px'}}>{errors["contact_of_buyer"]}</h5>}                            
                    </Form.GroupFields>
                    <Form.GroupFields>
                        <Form.Label htmlfor="financing_taken">
                        Financing Taken
                        <span
                              style={{ color: "red", marginLeft: "2px" }}
                            >
                              *
                        </span>                        
                        </Form.Label>
                        <Form.Dropdown
                            name="financing_taken"
                            id="financing_taken"
                            options={yes_no_drodpwn}
                            onChange={(e) => handleChange(e)}
                            value={liquidation["financing_taken"]}
                            style={styles}
                        />
                            {(errors?.hasOwnProperty("financing_taken") && errors["financing_taken"]) && <h5 style={{color: 'red',marginTop:'2px'}}>{errors["financing_taken"]}</h5>}                            
                </Form.GroupFields>
                {liquidation["financing_taken"] === "Yes" &&
                <>
                    <Form.GroupFields>
                        <Form.Label htmlfor="enter_financier_name">
                        Select Financer
                        <span
                              style={{ color: "red", marginLeft: "2px" }}
                            >
                              *
                        </span>
                        </Form.Label>
                        <Form.Dropdown
                            name="enter_financier_name"
                            id="enter_financier_name"
                            options={financerDropdown}
                            onChange={(e) => handleChange(e)}
                            value={liquidation["enter_financier_name"]}
                            style={styles}
                        />
                            {(errors?.hasOwnProperty("enter_financier_name") && errors["enter_financier_name"]) && <h5 style={{color: 'red',marginTop:'2px'}}>{errors["enter_financier_name"]}</h5>}                            
                </Form.GroupFields>
                {(liquidation["enter_financier_name"] || '').toLowerCase() === "other" && <Form.GroupFields>
                            <Form.Label htmlfor="specify_other">
                            Specify Other
                            <span
                                style={{ color: "red", marginLeft: "2px" }}
                            >
                                *
                            </span>                            
                            </Form.Label>
                            <Form.Input
                              name="specify_other"
                              id="specify_other"
                              type="text"
                              onChange={(e) => handleChange(e)}
                              value={liquidation["specify_other"]}
                              style={styles}
                            />
                            {(errors?.hasOwnProperty("specify_other") && errors["specify_other"]) && <h5 style={{color: 'red',marginTop:'2px'}}>{errors["specify_other"]}</h5>}                            
                    </Form.GroupFields>}
                    <Form.GroupFields>
                            <Form.Label htmlfor="enter_loan_amount">
                            Enter Loan Amount
                            <span
                                style={{ color: "red", marginLeft: "2px" }}
                            >
                                *
                            </span>                            
                            </Form.Label>
                            <Form.Input
                              name="enter_loan_amount"
                              id="enter_loan_amount"
                              type="number"
                              onChange={(e) => handleChange(e)}
                              value={liquidation["enter_loan_amount"]}
                              style={styles}
                            />
                            {(errors?.hasOwnProperty("enter_loan_amount") && errors["enter_loan_amount"]) && <h5 style={{color: 'red',marginTop:'2px'}}>{errors["enter_loan_amount"]}</h5>}                            
                    </Form.GroupFields>
                    <Form.GroupFields>
                            <Form.Label htmlfor="loan_number">
                            Loan Number                         
                            </Form.Label>
                            <Form.Input
                              name="loan_number"
                              id="loan_number"
                              type="text"
                              onChange={(e) => handleChange(e)}
                              value={liquidation["loan_number"]}
                              style={{ width:"100%",maxWidth: "100%", textTransform: 'uppercase'}}
                            />
                            {(errors?.hasOwnProperty("loan_number") && errors["loan_number"]) && <h5 style={{color: 'red',marginTop:'2px'}}>{errors["loan_number"]}</h5>}                            
                    </Form.GroupFields>
                    <Form.GroupFields>
                            <Form.Label htmlfor="hpa_number">
                            HPA Number                         
                            </Form.Label>
                            <Form.Input
                              name="hpa_number"
                              id="hpa_number"
                              type="text"
                              onChange={(e) => handleChange(e)}
                              value={liquidation["hpa_number"]}
                              style={{ width:"100%",maxWidth: "100%", textTransform: 'uppercase'}}
                            />
                            {(errors?.hasOwnProperty("hpa_number") && errors["hpa_number"]) && <h5 style={{color: 'red',marginTop:'2px'}}>{errors["hpa_number"]}</h5>}                            
                    </Form.GroupFields>
                    <Form.GroupFields>
                            <Form.Label htmlfor="hpa_date">
                            HPA Date                        
                            </Form.Label>
                            <Form.Input
                              name="hpa_date"
                              id="hpa_date"
                              type="date"
                              onChange={(e) => handleChange(e)}
                              value={liquidation["hpa_date"]}
                              style={styles}
                            />
                            {(errors?.hasOwnProperty("hpa_date") && errors["hpa_date"]) && <h5 style={{color: 'red',marginTop:'2px'}}>{errors["hpa_date"]}</h5>}                            
                    </Form.GroupFields>
                    </>
                  } 
                      <Form.GroupFields>
                            <Form.Label htmlfor="warranty_taken">
                            Is Warranty Taken?
                            <span
                                  style={{ color: "red", marginLeft: "2px" }}
                                >
                                  *
                            </span>                        
                            </Form.Label>
                            <Form.Dropdown
                                name="warranty_taken"
                                id="warranty_taken"
                                options={yes_no_drodpwn}
                                onChange={(e) => handleChange(e)}
                                value={liquidation["warranty_taken"]}
                                style={styles}
                            />
                                {(errors?.hasOwnProperty("warranty_taken") && errors["warranty_taken"]) && <h5 style={{color: 'red',marginTop:'2px'}}>{errors["warranty_taken"]}</h5>}                            
                    </Form.GroupFields>     
                        {liquidation["warranty_taken"] === "Yes" && 
                            <Form.GroupFields>
                            <Form.Label htmlfor="warranty_name">
                            Warranty Type
                            <span
                                  style={{ color: "red", marginLeft: "2px" }}
                                >
                                  *
                            </span>
                            </Form.Label>
                            <Form.Dropdown
                                name="warranty_name"
                                id="warranty_name"
                                options={warranty_name_dropdown}
                                onChange={(e) => handleChange(e)}
                                value={liquidation["warranty_name"]}
                                style={styles}
                            />
                                {(errors?.hasOwnProperty("warranty_name") && errors["warranty_name"]) && <h5 style={{color: 'red',marginTop:'2px'}}>{errors["warranty_name"]}</h5>}                            
                    </Form.GroupFields>
                        }            
                    <Form.GroupFields>
                            <Form.Label htmlfor="chassis_number">
                            Chassis Number
                            </Form.Label>
                            <Form.Input
                              name="chassis_number"
                              id="chassis_number"
                              type="text"
                              onChange={(e) => handleChange(e)}
                              disabled
                              value={liquidation["chassis_number"]}
                              style={styles}
                            />
                            {(errors?.hasOwnProperty("chassis_number") && errors["chassis_number"]) && <h5 style={{color: 'red',marginTop:'2px'}}>{errors["chassis_number"]}</h5>}                            
                    </Form.GroupFields>
                    <Form.GroupFields>
                          <Form.Label htmlfor="remarks">Remarks</Form.Label>
                          <Form.Textarea 
                            cols="30" 
                            rows="7"
                            name="remarks"
                            id="remarks"
                            onChange={(e) => handleChange(e)}
                            value={liquidation["remarks"]}
                            style={styles}
                          />
                    </Form.GroupFields>
        </>
        </>
    )
}