import React, { useContext, useEffect, useState }  from "react";
import { useNavigate, useParams } from "react-router-dom";
import Form from "../../components/form";
import Header from "../../components/header";
import { ProductContext } from "../../utils/productContext";
import ExpenseSourcing from "./formSection/expenseSourcing";
import ExpenseOperation from "./formSection/expenseOperation";
import ExpenseLiquidation from "./formSection/expenseLiquidation";
import Swal from "sweetalert2";
import { useQuery } from "../../utils/useQuery";
import { omit } from "lodash";
import uploadFileToBlob from "../../utils/azureStorageBlob";
import { usePostBE } from "../../utils/usePostBE";
import { API_BASE_URL, API_ROUTES } from "../../config";
import Loader from "../../components/Loader/Loader";
import urlContain from "../../utils/urlContain";
import { getFileExtension } from "../../utils/getFileExtension";
import { useDuplicateChassisNumber } from "../../utils/useDuplicateChassisNumber";

export default function ExpenseTrackerAddForm(){
    const [formType, setFormType] = useState("");
    const [blobList, setBlobList] = useState([]);
    const [imageList, setImageList] = useState("");
    const [formSection, setFormSection] = useState([])
    const { chassisNumber } = useParams();
    let navigate = useNavigate();
    const [disable, setDisable] = useState(true);
    const [postBody,setPostBody] = useState({
        "chassis_number": "",
        "user_id": NaN,
        "form_type":'',
        "form_data":''
    });
    const validImageTypes = ["image/gif","image/svg+xml" ,"image/jpeg","image/jpeg", "image/png","gif","svg+xml" ,"jpg","jpeg", "png"];   
    const queryParams = useQuery();
    const userid = queryParams.get("userid"); 
//    const product_id = queryParams.get("product_id");
//    const product_Name = queryParams.get("product_name");   


    const [sourcing,setSourcing] = useState({
        "form_type":"Sourcing",
        "sourcing_date":'',
        "purchase_cost":'',
        "transportation_charges_cost":'',
        "parking_charge_cost":'',
        "Source":'',
        "nbfc":'',
        "specify_other":'',
        "name_source":'',
        "contact_source":'',
        "sourcing_enabled_M_and_M":'',
        "chassis_number":'',
        "remarks":''
    });

    const [operation,setOperation] = useState({
        "form_type":"Operation",
        "cost_of_refurbishment_cost":"",
        "provision_image":null,
        "documentation_cost":'',
        "certification_cost":'',
        "others_cost":'',
        "insurance":'',
        "chassis_number":'',
        "remarks":''
    });

    const [liquidation,setLiquidation] = useState({
        "form_type":"Lquidation",
        "sale_date":'',
        "selling_price":'',
        "sold_to":'',
        "name_of_buyer":'',
        "contact_of_buyer":'',
        "financing_taken":'',
        'warranty_taken':'',
        'warranty_name':'',
        "enter_financier_name":'',
        "enter_loan_amount":'',
        "specify_other":'',
        "loan_number":'',
        "hpa_number":'',
        "hpa_date":'',
        "chassis_number":'',
        "remarks":''
    });
    
    const [postData, postFn, submittingBE, postStatus] = usePostBE({url:`${API_BASE_URL.BASE_URL}${API_ROUTES.ADD_UPDATE_EXPENSE_DATA}`,redirect:"Expense"});    
    const [duplicateData,DuplicateDataFn,duplicateFetching] = useDuplicateChassisNumber({url:`${API_BASE_URL.BASE_URL}${API_ROUTES.CHECK_UNIQUE_CHASSISNUMBER}`});
 
    useEffect(() => {
        if(postData){
          Swal.fire({
            icon: "success",
            text: `Submitted successfully`,
            confirmButtonColor: '#652078',
            }).then((res) => {
            if (res) {
             // handleRedirect();
             //here formSection.length is not updated
             if(formSection.length < 3 ){
                backButton();
             }
             reset();
            }
            })        
        }
      },[postData])

      useEffect(() => {
        let postBody = {
            "chassis_no" : chassisNumber,
            "category" : "expense"
        };
        DuplicateDataFn(postBody);
      },[chassisNumber,postData])

      useEffect(() => {
        let formSectionDrodown = [{value:"", label:"Select..."}];
        if(duplicateData){
            (sourcing.chassis_number === "") && setSourcing((pre) => ({...pre,"chassis_number":chassisNumber.toUpperCase()}));
            (operation.chassis_number === "") && setOperation((pre) => ({...pre,"chassis_number":chassisNumber.toUpperCase()}));
            (liquidation.chassis_number === "") && setLiquidation((pre) => ({...pre,"chassis_number":chassisNumber.toUpperCase()}));

            if(!duplicateData?.data?.is_sourcing){
                formSectionDrodown.push({value:"Sourcing", label:"Buying"})
            }
            if(!duplicateData?.data?.is_operation){
                formSectionDrodown.push({value:"Operation", label:"Refurbishment"})
            }
            if(!duplicateData?.data?.is_liquidation){
                formSectionDrodown.push({value:"Liquidation", label:"Selling"})
            }

        }
        setFormSection(formSectionDrodown)
      },[duplicateData])

      useEffect(() => {

    if(!(Object.keys(errorReturn)?.length>0) && operation["provision_image"] !== null && operation["provision_image"] && ( blobList?.length !== 0 || blobList !== "")){ 
    let  postBody_var = "";
    let operation_imageVar = operation;
    urlContain(blobList).length > 0 ? (operation_imageVar = {...operation_imageVar, [["provision_image"]]: urlContain(blobList)}) :(operation_imageVar = {...operation_imageVar, [["provision_image"]]: null})
    setOperation(operation_imageVar);

    if(formType.toLowerCase() === "operation"){
        !(Object.keys(errorReturn)?.length>0) && Object.keys(operation).map((itemSource) => {
            if((itemSource.endsWith("_cost") || itemSource.endsWith("_price") || itemSource.endsWith("_amount")) && operation[itemSource] === "" ){
                operation_imageVar = omit(operation_imageVar, itemSource);
            }
            // else if((itemSource.endsWith("_cost") || itemSource.endsWith("_price") || itemSource.endsWith("_amount")) && operation[itemSource] !== "" ){
            //     operation_imageVar = {...operation_imageVar, [itemSource]:parseFloat(operation[itemSource])};
            // } 
        });
          postBody_var = {
                chassis_number:operation.chassis_number,
                user_id:userid,
                form_type:formType,
                form_data:operation_imageVar
            }
        } 
       // console.log("post with image",postBody_var,imageList,"bloblist",urlContain(blobList),operation)
          postFn(postBody_var)
    };
        
      },[blobList])



    const handleRedirect = () => {
      window.location.href =
        window.location.href + "&submit.jotform.com/submit";
    };

    const reset = (name) => {
        setFormType("");
        setDisable(true);
        setSourcing(pre => ({...pre,
            "form_type":"Sourcing",
            "sourcing_date":'',
            "purchase_cost":'',
            "transportation_charges_cost":'',
            "parking_charge_cost":'',
            "Source":'',
            "nbfc":'',
            "specify_other":'',
            "name_source":'',
            "contact_source":'',
            "sourcing_enabled_M_and_M":'',
            "remarks":''
        }));
    
        setOperation(pre => ({...pre,
            "form_type":"Operation",
            "cost_of_refurbishment_cost":"",
            "provision_image":null,
            "documentation_cost":'',
            "certification_cost":'',
            "others_cost":'',
            "insurance":'',
            "remarks":''
        }));
    
        setLiquidation(pre => ({...pre,
            "form_type":"Lquidation",
            "sale_date":'',
            "selling_price":'',
            "sold_to":'',
            "name_of_buyer":'',
            "contact_of_buyer":'',
            "financing_taken":'',
            'warranty_taken':'',
            'warranty_name':'',
            "enter_financier_name":'',
            "specify_other":'',
            "enter_loan_amount":'',
            "loan_number":'',
            "hpa_number":'',
            "hpa_date":'',
            "remarks":''
        }));
    }


    const {
        errors,
        setErrors
      } = useContext(ProductContext);
    
    let errorObj = errors;
    var errorReturn = "";

    const validate = (event, name, value) => {

        if(formType.toLowerCase() === "sourcing"){
            if(value === "" && name==="sourcing_date" && name !== "remarks"){
                errorObj =  {...errorObj, [name]: "Please enter sourcing date"};
              }else if(value === "" && name==="purchase_cost" && name !== "remarks"){
                errorObj =  {...errorObj, [name]: "Please enter purchase cost"};
              }else if(value !== "" && name==="purchase_cost" && !((/^[1-9][0-9]*$/).test(value)) && name !== "remarks"){
                  errorObj =  {...errorObj, [name]: "Please fill valid number (Eg. 123)."};
              }else if(value !== "" && !(value.length >= 4 && value.length <= 7) && name==="purchase_cost" && name !== "remarks"){
                errorObj =  {...errorObj, [name]: "Enter value between 4 to 7 digits."};
              }else if(value === "" && name==="transportation_charges_cost" && name !== "remarks"){
                errorObj =  {...errorObj, [name]: "Value is required. Enter 0 in case of no cost."};
              }else if(value !== "" && name==="transportation_charges_cost" && !((/^[0-9][0-9]*$/).test(value)) && name !== "remarks"){
                errorObj =  {...errorObj, [name]: "Please fill valid number (Eg. 0123)."};
              }else if(value === "" && name==="parking_charge_cost" && name !== "remarks"){
                errorObj =  {...errorObj, [name]: "Value is required. Enter 0 in case of no cost."};
              }else if(value !== "" && name==="parking_charge_cost" && !((/^[0-9][0-9]*$/).test(value)) && name !== "remarks"){
                errorObj =  {...errorObj, [name]: "Please fill valid number (Eg. 0123)."};
              }else if(value === "" && name==="Source" && name !== "remarks"){
                errorObj =  {...errorObj, [name]: "Please select source."};
              }else if(value === "" && (sourcing["Source"] === "NBFC – Direct" || sourcing["Source"] === "NBFC – Auction") && name==="nbfc" &&  name !== "remarks"){
                errorObj =  {...errorObj, [name]: "Please select nbfc."};
              }else if(value === "" && (sourcing["nbfc"] || '').toLowerCase() === "other" && name === "specify_other" &&  name !== "remarks"){
                errorObj =  {...errorObj, [name]: "Please specify other."};
              }else if(value !== "" && (sourcing["nbfc"] || '').toLowerCase() === "other" && name==="specify_other" && !((/^[a-zA-Z ]{2,30}$/).test(value)) && name !== "remarks"){
                errorObj =  {...errorObj, [name]: "Please specify other."};
              }else if(value === "" && name==="name_source"  && (sourcing["Source"] === "Dealer – Other" || sourcing["Source"] === "Broker" || sourcing["Source"] === "Farmer") && name !== "remarks"){
                errorObj =  {...errorObj, [name]: "Please enter name of source."};
              }else if(value !== "" && name==="name_source" && !((/^[a-zA-Z ]{2,30}$/).test(value)) && name !== "remarks"){
                  errorObj =  {...errorObj, [name]: "Please enter valid name."};
              }else if(value !== "" && name==="contact_source" && (isNaN(value) || !((/^[0-9]{10}/).test(value)) || value.length > 10) ){
                ///^[6-9][0-9]{10}/
                errorObj =  {...errorObj, [name]: "Please enter a valid phone number"};
              }else if(errors?.hasOwnProperty(name)){
                let newObj = omit(errors, name);
                errorObj = newObj;
              }
        }
        

        if(formType.toLowerCase() === "operation"){
            if(value !== "" && name==="cost_of_refurbishment_cost" && !((/^[1-9][0-9]*$/).test(value)) && name !== "remarks"){
                errorObj =  {...errorObj, [name]: "Please fill valid number (Eg. 123)."};
            }else if(value?.length > 0 && value?.[0]?.['type'] && !validImageTypes.includes(value?.[0]?.['type']) && name==="provision_image" && name !== "remarks"){
                //console.log(!isValidUrl(value) && !value?.[0]?.['type'])  
                errorObj =  {...errorObj, [name]: "Please select valid image eg. jpg, png etc.."};
            }else if(value && value !== "" && !isValidUrl(value) && !value?.[0]?.['type'] && !validImageTypes.includes(getFileExtension(value)) && name==="provision_image" && name !== "remarks"){
                //console.log(!isValidUrl(value) && !value?.[0]?.['type'],getFileExtension(value))
                errorObj =  {...errorObj, [name]: "Please select valid image eg. jpg, png etc.."};
            }else if(value !== "" && name==="documentation_cost" && !((/^[1-9][0-9]*$/).test(value)) && name !== "remarks"){
                errorObj =  {...errorObj, [name]: "Please fill valid number (Eg. 123)."};
            }else if(value !== "" && name==="certification_cost" && !((/^[1-9][0-9]*$/).test(value)) && name !== "remarks"){
                errorObj =  {...errorObj, [name]: "Please fill valid number (Eg. 123)."};
            }else if(value !== "" && name==="others_cost" && !((/^[1-9][0-9]*$/).test(value)) && name !== "remarks"){
                errorObj =  {...errorObj, [name]: "Please fill valid number (Eg. 123)."};
            }else if(errors?.hasOwnProperty(name)){
                let newObj = omit(errors, name);
                errorObj = newObj;
              }
        }
        if(formType.toLowerCase() === "liquidation"){
            if(value === "" && name==="sale_date" && name !== "remarks"){
                errorObj =  {...errorObj, [name]: "Please select sale date"};
            }else if(value === "" && name==="selling_price" && name !== "remarks"){
                errorObj =  {...errorObj, [name]: "Please enter selling price"};
            }else if(value !== "" && name==="selling_price" && !((/^[1-9][0-9]*$/).test(value)) && name !== "remarks"){
                errorObj =  {...errorObj, [name]: "Please fill valid number (Eg. 123)."};
            }else if(value !== "" && !(value.length >= 4 && value.length <= 7) && name === "selling_price" && name !== "remarks"){
                errorObj =  {...errorObj, [name]: "Enter value between 4 to 7 digits."};
            }else if(value === "" && name==="sold_to" && name !== "remarks"){
                errorObj =  {...errorObj, [name]: "Please select sold to."};
            }else if(value === "" && name==="name_of_buyer" && name !== "remarks"){
                errorObj =  {...errorObj, [name]: "Please enter name of buyer."};
            }else if(value !== "" && name==="name_of_buyer" && !((/^[a-zA-Z ]{2,30}$/).test(value)) && name !== "remarks"){
                errorObj =  {...errorObj, [name]: "Please enter valid name."};
            }else if(value !== "" && name==="contact_of_buyer" && (isNaN(value) || !((/^[0-9]{10}/).test(value)) || value.length > 10) ){
                ///^[6-9][0-9]{10}/
                errorObj =  {...errorObj, [name]: "Please enter a valid phone number"};
            }else if(value === "" && name==="financing_taken" && name !== "remarks"){
                errorObj =  {...errorObj, [name]: "Please select financing taken."};
            }else if(value === "" && name==="warranty_taken" && name !== "remarks"){
                errorObj =  {...errorObj, [name]: "Please select warranty taken."};
            }else if(value === "" && liquidation["warranty_taken"] === "Yes" && name==="warranty_name" && name !== "remarks"){
                errorObj =  {...errorObj, [name]: "Please select Warranty name"};
            }else if(value === "" && liquidation["financing_taken"] === "Yes" && name==="enter_financier_name" && name !== "remarks"){
                errorObj =  {...errorObj, [name]: "Please enter financier name"};
            }else if(value === "" && (liquidation["enter_financier_name"] || '').toLowerCase() === "other" && name==="specify_other" && name !== "remarks"){
                errorObj =  {...errorObj, [name]: "Please specify other."};
            }else if(value !== "" && (liquidation["enter_financier_name"] || '').toLowerCase() === "other" && name==="specify_other" && !((/^[a-zA-Z ]{2,30}$/).test(value)) && name !== "remarks"){
                errorObj =  {...errorObj, [name]: "Please specify valid name."};
            }else if(value === "" && liquidation["financing_taken"] === "Yes" && name==="enter_loan_amount" && name !== "remarks"){
                errorObj =  {...errorObj, [name]: "Please enter loan amount"};
            }else if(value !== "" && liquidation["financing_taken"] === "Yes" && name==="enter_loan_amount" && !((/^[1-9][0-9]*$/).test(value)) && name !== "remarks"){
                errorObj =  {...errorObj, [name]: "Please fill valid number (Eg. 123)."};
            }else if(value !== "" && liquidation["financing_taken"] === "Yes" && name==="loan_number" && !((/^(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9]+$/).test(value)) && name !== "remarks"){
                errorObj =  {...errorObj, [name]: "Loan Number should only contain alphanumeric characters."};
            }else if(value !== "" && liquidation["financing_taken"] === "Yes" && name==="hpa_number" && !((/^(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9]+$/).test(value)) && name !== "remarks"){
                errorObj =  {...errorObj, [name]: "HPA Number should only contain alphanumeric characters."};
            }else if(errors?.hasOwnProperty(name)){
                let newObj = omit(errors, name);
                errorObj = newObj;
            }
        }
        if(name==="unique_chassis_number"){
            if(value !== ""  && (value.length < 5 || value.length > 25) && name !== "remarks"){
                errorObj = {...errorObj, [name]: "Chassis number should be between 5 to 25 character"};
            }else if(value !== "" && name==="unique_chassis_number"  && !((/^(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9]+$/).test(value)) && name !== "remarks"){
                errorObj = {...errorObj, [name]: "Chassis number should be alphaNumeric"};
            }else if(errors?.hasOwnProperty(name)){
                let newObj = omit(errors, name);
                errorObj = newObj;
            }
        }
        return errorObj;
    }    

    const removeConditionalField = (name,value) => {
        if(formType.toLowerCase() === "sourcing"){
            let rmvSourceKey = sourcing;
            if(name === "Source" && (value === "Dealer – Other" || value === "Broker" || value === "Farmer")){
                rmvSourceKey = {...rmvSourceKey, "name_source":''}
            }else if(name === "Source" && (value === "NBFC – Direct" || value === "NBFC – Auction")){
                rmvSourceKey = omit(sourcing, "name_source");
                rmvSourceKey = {...rmvSourceKey, "nbfc":''}
                errorObj = omit(errors, "name_source");
            }else if(name === "Source" && (value !== "NBFC – Direct" || value !== "NBFC – Auction")){
                rmvSourceKey = omit(sourcing, "nbfc","specify_other");
                errorObj = omit(errors,"nbfc","specify_other");
            }else if(name === "Source" && value === "Dealer – Mahindra & Swaraj"){
                rmvSourceKey = omit(sourcing, "name_source","nbfc","specify_other");
                errorObj = omit(errors,"name_source","nbfc");
            }else if(name === "nbfc" && value.toLowerCase() !== "other" ){
                rmvSourceKey = omit(sourcing, "specify_other");
                errorObj = omit(errors,"specify_other");
            }else if(name === "nbfc" && value.toLowerCase() === "other" ){
                rmvSourceKey = { ...rmvSourceKey, "specify_other":''}
            }
            setSourcing(rmvSourceKey);
            setErrors(errorObj);
        }else if(formType.toLowerCase() === "liquidation"){
            let rmvLiquidationKey = liquidation;
            if(name === "financing_taken" && value === "No"){
                rmvLiquidationKey = omit(liquidation, "enter_financier_name","specify_other","enter_loan_amount","loan_number","hpa_number","hpa_date");
                errorObj = omit(errors, "enter_financier_name","specify_other","enter_loan_amount");
            }else if(name === "warranty_taken" && value === "No"){
                rmvLiquidationKey = omit(liquidation, "warranty_name");
                errorObj = omit(errors, "enter_financier_name","specify_other","enter_loan_amount");
            }else if(name === "enter_financier_name" && value.toLowerCase() !== "other" ){
                rmvLiquidationKey = omit(liquidation, "specify_other");
                errorObj = omit(errors, "specify_other");
            }else if(name === "enter_financier_name" && value.toLowerCase() === "other" ){
                rmvLiquidationKey = { ...rmvLiquidationKey, "specify_other":''}
            }else if(name === "financing_taken" && value === "Yes" ){
                rmvLiquidationKey = { ...rmvLiquidationKey, "enter_financier_name":'',"enter_loan_amount":'',"loan_number":'',"hpa_number":'',"hpa_date":''}
            }else if(name === "warranty_taken" && value === "Yes"){
                rmvLiquidationKey = omit(liquidation, "warranty_name");
                errorObj = omit(errors, "warranty_name");
            }
            setLiquidation(rmvLiquidationKey);
            setErrors(errorObj);
        }
    }


    const handleChange = (e) => {
        let {type,name,value,files} = e.target;
        
        if(formType.toLowerCase() === "sourcing" || formType.toLowerCase() === "liquidation"){
            removeConditionalField(name,value);
        }

        if(type === "file"){
          //  console.log("files",files,files.length > 0,(type ==="file" && files?.length > 0) && files?.[0]?.name)
            errorReturn = validate(e, name, files);
            files.length > 0 ? setImageList(files[0]) : setImageList("");
            setErrors(errorReturn);    
        }else{
            errorReturn = validate(e, name, value);
            setErrors(errorReturn);
        } 

        let FormTypeVar = formType;

        if(name === "form_type") {
            FormTypeVar = value;
            FormTypeVar !== "" ? setDisable(false):setDisable(true);
            setFormType(FormTypeVar);
            setErrors({});
        }

        (FormTypeVar.toLowerCase() === "sourcing") && setSourcing((pre) => ({...pre,[name]:value}));
        (FormTypeVar.toLowerCase() === "operation") && setOperation((pre) => ({...pre,[name]: (type ==="file" && files?.length > 0) ? files?.[0]?.name : value}));
        (FormTypeVar.toLowerCase() === "liquidation") && setLiquidation((pre) => ({...pre,[name]:value}));

    }

    const uploadimage = async () => {
        let bloblistConatiner = "";
        const containerName = `expense/${userid}`;

        bloblistConatiner = await uploadFileToBlob(imageList,containerName);
        bloblistConatiner !== "" && setBlobList(bloblistConatiner);
    }

    function isValidUrl(string) {
        try {
          new URL(string);
          return true;
        } catch (err) {
          return false;
        }
      }


    const handleSubmit = (e) => {
        e.preventDefault();   
    let field = "";
    let postBody_var = postBody;
    if(formType.toLowerCase() === "sourcing"){
        field = sourcing;
        var defaultFieldJsonKey = Object.keys(field); 
        for(var i = 0; i < defaultFieldJsonKey.length; i++){
          if(defaultFieldJsonKey){
              errorReturn = validate(e, defaultFieldJsonKey[i], field[defaultFieldJsonKey[i]]);
          }
        }    
        !(Object.keys(errorReturn)?.length>0) && Object.keys(sourcing).map((itemSource) => {
            if((itemSource.endsWith("_cost") || itemSource.endsWith("_price") || itemSource.endsWith("_amount")) && sourcing[itemSource] === "" ){
                field =  omit(field, itemSource);
            }
            // else if((itemSource.endsWith("_cost") || itemSource.endsWith("_price") || itemSource.endsWith("_amount")) && sourcing[itemSource] !== "" ){
            //     field = {...field, [itemSource]:parseFloat(sourcing[itemSource])};
            // }
        });

        postBody_var = {
            chassis_number:sourcing.chassis_number,
            user_id:userid,
            form_type:formType,
            form_data:field
        }
    }else if(formType.toLowerCase() === "operation"){
        field = operation;
        defaultFieldJsonKey = Object.keys(field); 
        for( i = 0; i < defaultFieldJsonKey.length; i++){
          if(defaultFieldJsonKey){
              errorReturn = validate(e, defaultFieldJsonKey[i], field[defaultFieldJsonKey[i]]);
          }
        }
        if(field["cost_of_refurbishment_cost"] === "" && field["documentation_cost"] === "" && field["certification_cost"] === "" && field["others_cost"] === ""){
            Swal.fire({
                icon: "error",
                text: "Please fill at least one cost or amount."
              }).then((res) => {
                
              });
              return -1;
        }
        !(Object.keys(errorReturn)?.length>0) && Object.keys(operation).map((itemoperation) => {
            if((itemoperation.endsWith("_cost") || itemoperation.endsWith("_price") || itemoperation.endsWith("_amount")) && operation[itemoperation] === "" ){
                field = omit(field, itemoperation);
            }
            // else if((itemoperation.endsWith("_cost") || itemoperation.endsWith("_price") || itemoperation.endsWith("_amount")) && operation[itemoperation] !== "" ){
            //     field = {...field, [itemoperation]:parseFloat(operation[itemoperation])};
            // }
        });

        operation.provision_image !== null && uploadimage();
        postBody_var = {
            chassis_number:operation.chassis_number,
            user_id:userid,
            form_type:formType,
            form_data:field
        }
    }else if(formType.toLowerCase() === "liquidation"){
        field = liquidation;
        defaultFieldJsonKey = Object.keys(field); 
        for( i = 0; i < defaultFieldJsonKey.length; i++){
          if(defaultFieldJsonKey){
              errorReturn = validate(e, defaultFieldJsonKey[i], field[defaultFieldJsonKey[i]]);
          }
        }    
        !(Object.keys(errorReturn)?.length>0) && Object.keys(liquidation).map((itemSource) => {
            if((itemSource.endsWith("_cost") || itemSource.endsWith("_price") || itemSource.endsWith("_amount")) && liquidation[itemSource] === "" ){
                field = omit(field, itemSource);
            }
            // else if((itemSource.endsWith("_cost") || itemSource.endsWith("_price") || itemSource.endsWith("_amount")) && liquidation[itemSource] !== "" ){
            //     field = {...field, [itemSource]:parseFloat(liquidation[itemSource])};
            // }
        });
        postBody_var = {
            chassis_number:liquidation.chassis_number,
            user_id:userid,
            form_type:formType,
            form_data:field
        }
    }   
    setPostBody(postBody_var);
    //console.log("postBody_var",postBody_var);
   // console.log("dfs",liquidation, sourcing,errors)
      setErrors(errorReturn);
      if(!(Object.keys(errorReturn)?.length>0)){
        if(operation["provision_image"] === null || !operation["provision_image"]){
           postFn(postBody_var);
        }
      }else{
          Swal.fire({
            icon: "error",
            text: "Please fill all the mandatory fields or valid inputs."
          }).then((res) => {
            
          });
      }
    }

    const backButton = () => {
        setErrors({})
        navigate(`/expenseHome?userid=${userid}`)
    }

    return (
        <>
        <Header>
            <Header.BackButtonContainer>
                <Header.BackIcon src="../assets/arrow_back.svg" alt="" onClick={backButton} />
                <Header.ButtonText>Add Expense Tracker Form</Header.ButtonText>
            </Header.BackButtonContainer>
        </Header>
            <Form onSubmit={handleSubmit}>
               <Form.SectionContainer shadow={true}>
                    <Form.Section>
                        <Form.GroupFields>
                                <Form.Label htmlFor="form_type">
                                Expense Category 
                                </Form.Label>
                                <Form.Dropdown
                                name="form_type"
                                id="form_type"
                                options={formSection}
                                onChange={(e) => handleChange(e)}
                                value={formType}
                                disabled={(formSection.length === 1 && !duplicateFetching) ? true : false}
                                style={{width:'100%', maxWidth:'100%'}}
                                />
                        </Form.GroupFields>                        
                        {(formType.toLowerCase() === "sourcing") && <ExpenseSourcing handleChange={handleChange} sourcing={sourcing} />}
                        {(formType.toLowerCase() === "operation") && <ExpenseOperation handleChange={handleChange} operation={operation} />} 
                        {(formType.toLowerCase() === "liquidation") && <ExpenseLiquidation handleChange={handleChange} liquidation={liquidation} />}
                    </Form.Section>
                <Form.ButtonContainer>
                {formType !== "" ? submittingBE ? <Loader /> : <Form.Button disabled={disable}>SUBMIT</Form.Button> : ""}
                </Form.ButtonContainer>
                </Form.SectionContainer>
            </Form>
        </>
    )
}